/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLeading } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import url from '../../api'
import {
  getHospitalAdmin,
  getHospitalAdminError,
  getHospitalAdminSuccess,
  updateHospitalAdmin,
  updateHospitalAdminError,
  updateHospitalAdminSuccess
} from '../actions/hospitalAdminActions'
import { storeAuthToken } from '../actions/authActions'
import { getAuthToken } from './authSaga'

function* getHospitalAdminSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const { hospitalId, user_id } = action.payload
    const response: any = yield call(
      url.get,
      `/hospitals/${hospitalId}/admin?user_id=${user_id}`
    )
    const { data } = response.data
    yield put(getHospitalAdminSuccess(data))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(getHospitalAdminError(error))
    }
    if (error.response.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(getHospitalAdminError(message))
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}

function* updateHospitalAdminSaga(action: any): Generator {
  try {
    const _authToken: any = yield call(getAuthToken)
    yield put(
      storeAuthToken({ token: _authToken.jwtToken, issuer: _authToken.issue })
    )
    const { hospitalId, hospitalAdminData } = action.payload
    const exUserName = { ...hospitalAdminData, user_name: '.' }
    const { user_id } = hospitalAdminData
    const response: any = yield call(
      url.put,
      `/hospitals/${hospitalId}/admin`,
      exUserName
    )
    const { data } = response.data
    toast.success('Hospital Admin updated sucessfully')
    yield put(updateHospitalAdminSuccess(data))
    yield put(getHospitalAdmin({ hospitalId, user_id }))
  } catch (error: Error | any) {
    if (error.toString() === 'Error: Network Error') {
      toast.error('Please check your network connection and try again')
      yield put(updateHospitalAdminError(error))
    }

    if (error?.response?.data) {
      const {
        data: { message }
      } = error.response
      toast.error(message)
      yield put(updateHospitalAdminError(message))
    } else {
      // eslint-disable-next-line no-console
      console.log('error', error)
    }
  }
}

function* hospitalSaga() {
  yield takeLeading(getHospitalAdmin, getHospitalAdminSaga)
  yield takeLeading(updateHospitalAdmin, updateHospitalAdminSaga)
}

export default hospitalSaga
